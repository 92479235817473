import {type ReactNode} from 'react';

import Section from '@/components/base/layouts/Section/Section';
import {BlogArticleContent} from '@/components/blog/BlogArticleContent/BlogArticleContent';
import BlogKeepUpSection from '@/components/blog//BlogKeepUpSection/BlogKeepUpSection';
import {useSharedTranslations, useTranslations} from '@/hooks/useTranslations';
import ArticleCarousel from '@/components/pages/blog/ArticleCarousel/ArticleCarousel';
import {fetchReviewerName} from '@/components/blog/utils';

import {BLOG_PAGE_TYPE} from '../types';
import type {
  Article,
  ContentNavigationLink,
  Settings,
  BlogCardClassNames,
} from '../types';
import {useDuxClickUnsubscribe} from '../hooks/useDuxClickUnsubscribe';

import BlogArticleHero from './BlogArticleHero/BlogArticleHero';
import {articleSectionStyles, popularArticlesStyles} from './styles';

const AI_SUMMARY_COMPONENT_NAME =
  "[data-component-name='blog-show-ai-summary-button']";

interface BlogArticleProps {
  article: Article;
  enBlogPath: string;
  popularPosts: Article[];
  contentNavigationLinks: ContentNavigationLink[];
  articleContentHtml: string;
  blogLinkText: string;
  settings: Settings;
  blogPageType: BLOG_PAGE_TYPE;
  header?: ReactNode | null;
  blogCardStyles?: BlogCardClassNames;
}

export default function BlogArticle({
  article,
  enBlogPath,
  popularPosts,
  contentNavigationLinks,
  articleContentHtml,
  blogLinkText,
  settings,
  blogPageType,
  header,
}: BlogArticleProps) {
  const {t} = useSharedTranslations(`pages${enBlogPath}$article/content`);
  const {localizePath} = useTranslations();
  useDuxClickUnsubscribe({
    componentSelector: AI_SUMMARY_COMPONENT_NAME,
    articleContentHtml,
  });

  const {subscription, images} = settings;
  const currentPagePath = `${localizePath(enBlogPath)}/${article.handle}`;

  const isEnterpriseBlog = blogPageType === BLOG_PAGE_TYPE.ENTERPRISE;
  const isEngineeringBlog = blogPageType === BLOG_PAGE_TYPE.ENGINEERING;

  const articleMetaTags = (
    <>
      <meta itemProp="mainEntityOfPage" content={currentPagePath} />
      <meta
        itemProp="dateModified"
        content={new Date(article.modifiedAtRaw).toISOString()}
      />
      {article.createdAtRaw && (
        <meta
          itemProp="datePublished"
          content={new Date(article.createdAtRaw).toISOString()}
        />
      )}
    </>
  );

  return (
    <Section
      className={articleSectionStyles({blogType: blogPageType})}
      componentName={article.handle}
    >
      {header}
      <article
        itemScope
        itemType="https://schema.org/Article"
        className="featured-blogs__section"
      >
        {articleMetaTags}
        <BlogArticleHero
          blogType={blogPageType}
          blogLinkText={blogLinkText}
          article={article}
          reviewerName={fetchReviewerName(article.metafields)}
          enBlogPath={enBlogPath}
        />
        <BlogArticleContent
          content={articleContentHtml}
          title={article.title}
          author={article.authorV2}
          modifiedAt={article.modifiedAt}
          authorAvatarUrl={article.authorV2?.avatarUrl}
          popularPosts={popularPosts}
          enBlogPath={enBlogPath}
          contentNavigationLinks={contentNavigationLinks}
          settings={settings}
          blogPageType={blogPageType}
          articleHandle={article.handle}
          metafields={article.metafields}
        />
        <div
          itemProp="publisher"
          itemScope
          itemType="https://schema.org/Organization"
        >
          <meta itemProp="name" content="Shopify" />
          <div
            itemProp="logo"
            itemScope
            itemType="https://schema.org/ImageObject"
          >
            <meta
              itemProp="url"
              content="https://cdn.shopify.com/assets/images/logos/shopify_logo_black.png"
            />
            <meta itemProp="width" content="210" />
            <meta itemProp="height" content="60" />
          </div>
        </div>
      </article>
      {popularPosts.length > 0 && (
        <ArticleCarousel
          blogPageType="article"
          blogType={blogPageType}
          primaryArticles={popularPosts}
          enBlogPath={enBlogPath}
          heading={t('popularPostsTitle')}
          headingTag={isEnterpriseBlog ? 'h3' : 'h5'}
          carouselName="popular-posts"
          className={popularArticlesStyles({blogType: blogPageType})}
          mode="default"
          blogCardMode={isEnterpriseBlog ? 'light' : undefined}
        />
      )}
      {!isEngineeringBlog && (
        <BlogKeepUpSection
          {...t('subscription')}
          subscriptionId={subscription?.settings?.subscriptionId}
          imageSrc={images.subscription.image.en}
          blogType={blogPageType}
          mode={
            blogPageType === BLOG_PAGE_TYPE.ENTERPRISE
              ? BLOG_PAGE_TYPE.ENTERPRISE
              : 'default'
          }
        />
      )}
    </Section>
  );
}
